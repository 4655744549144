import Joi from 'joi';
import moment from 'moment';
import { SCHEDULED_EVENT } from '../../../lib/liveEventScheduleTypes';
import { BROADCAST_EVENT, REMOTE_EVENT } from '../../../lib/liveEventTypes';

const VALID_CLASSIFICATION_CODES = ['P', 'C', 'G', 'PG', 'M', 'MA', 'AV', 'X', 'NA', 'U', 'TB'];
const STRING_WITHOUT_SPACES_REGEX = /^\S*$/;
// csv input date format: 24/07/2024 23:20:00
const DATE_REGEX = /^[0-9]{2}[\/]{1}[0-9]{2}[\/]{1}[0-9]{4} [0-9]{2}:[0-9]{2}:[0-9]{2}$/;
const DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm:ss';
export const INVALID_FILE_ERROR = 'Invalid file type';
export const FETCH_GENRE_ERROR = 'Something went wrong please try again later';
export const INVALID_CSV_FILE_CONTENT_ERROR = 'CSV file content is invalid';

export const CSV_HEADER = {
  // Mandatory fields specified by COPS
  mandatoryFields: [
    'external id',
    'event name',
    'event type',
    'event group',
    'promo start date/time',
    'availability start date/time',
    'availability end date/time',
    'image',
    'logo',
  ],
  optionalFields: [
    'video group',
    'subtitle',
    'description',
    'genre name',
    'classification',
    'season',
    'up next genre name',
    'up next classification',
    'up next name',
    'up next description',
    'up next subtitle',
    'up next program start time',
    'up next program end time',
    'fast channel',
    'fast channel id',
    'program start time',
    'program end time',
    'pre roll',
    'producer notes',
    'content targeting categories',
  ],
  eventTypeFields: [
    'enable ssai',
    'enable live sports',
    'enable multi url',
    'oztam id',
    'remote asset url',
    'ssai remote url',
    'akamai cdn clear url',
    'akamai cdn tokenisation url',
    'akamai cdn encryption url',
    'akamai cdn tokenisation encryption url',
    'cloudfront cdn clear url',
    'cloudfront cdn tokenisation url',
    'cloudfront cdn encryption url',
    'cloudfront cdn tokenisation encryption url',
    'ssai clear url',
    'ssai encryption url',
    'catalog codes',
    'material keys',
  ],
};

function getValidDate(dateTimeString) {
  if (!DATE_REGEX.test(dateTimeString)) {
    return null;
  }
  return moment(dateTimeString, DATE_TIME_FORMAT).toISOString();
}

const validateRemoteUrl = (value, helper, message) => {
  const validExtensions = /\.(m3u8|mpd)$/;
  return validExtensions.test(value) || helper.message(message);
};

const notPermittedMessage = (fieldName) => `${fieldName} field is not permitted.`;

// Fields for remote events
const BULK_REMOTE_EVENT_FIELDS_SCHEMA = Joi.object({
  remoteURL: Joi.string()
    .required()
    .custom((value, helper) => validateRemoteUrl(value, helper, 'Invalid remote URL extension')),
  ssaiRemoteURL: Joi.string()
    .required()
    .custom((value, helper) => validateRemoteUrl(value, helper, 'Invalid SSAI Remote URL extension')),
  isSports: Joi.boolean().required(),
  ssaiEnabled: Joi.boolean().required(),
  multiUrlEnabled: Joi.boolean().required(),
  oztamPublisherId: Joi.string().required(),
  liveEventUrls: Joi.when('multiUrlEnabled', {
    is: true,
    then: Joi.object({
      akamaiCdnUrls: Joi.object({
        clearUrl: Joi.string().uri().required(),
        tokenisationUrl: Joi.string().uri().required(),
        encryptionUrl: Joi.string().uri().required(),
        tokenisationEncryptionUrl: Joi.string().uri().required(),
      }).required(),
      cloudfrontCdnUrls: Joi.object({
        clearUrl: Joi.string().uri().required(),
        tokenisationUrl: Joi.string().uri().required(),
        encryptionUrl: Joi.string().uri().required(),
        tokenisationEncryptionUrl: Joi.string().uri().required(),
      }).required(),
      ssaiClearUrl: Joi.string().uri().required(),
      ssaiEncryptionUrl: Joi.string().uri().required(),
    }).required(),
    otherwise: Joi.forbidden().messages({
      'any.only': notPermittedMessage('multiUrlEnabled must be true to allow Live Event URLs field'),
    }),
  }),
  catalogCodes: Joi.optional()
    .valid(null)
    .messages({
      'any.only': notPermittedMessage('Catalog Codes'),
    }),
  materialKeys: Joi.optional()
    .valid(null)
    .messages({
      'any.only': notPermittedMessage('Material Keys'),
    }),
});

// Fields for broadcast events
const BULK_BROADCAST_EVENT_FIELDS_SCHEMA = Joi.object({
  ssaiEnabled: Joi.boolean()
    .valid(false)
    .required()
    .messages({
      'any.only': notPermittedMessage('SSAI Enabled'),
    }),
  isSports: Joi.boolean()
    .valid(false)
    .required()
    .messages({
      'any.only': notPermittedMessage('Is Sports'),
    }),
  multiUrlEnabled: Joi.boolean()
    .valid(false)
    .required()
    .messages({
      'any.only': notPermittedMessage('Multi URL Enabled'),
    }),
  oztamPublisherId: Joi.optional()
    .valid(null)
    .messages({
      'any.only': notPermittedMessage('Oztam Publisher Id'),
    }),
  remoteURL: Joi.optional()
    .valid(null)
    .messages({
      'any.only': notPermittedMessage('Remote URL'),
    }),
  ssaiRemoteURL: Joi.optional()
    .valid(null)
    .messages({
      'any.only': notPermittedMessage('SSAI Remote URL'),
    }),
  liveEventUrls: Joi.forbidden().messages({
    'any.only': notPermittedMessage('Live Event URLs'),
  }),
  catalogCodes: Joi.alternatives().try(
    Joi.array().items(Joi.string().required()).required(),
    Joi.valid(null)
  ),
  materialKeys: Joi.alternatives()
    .try(Joi.array().items(Joi.string().required()).required(), Joi.valid(null))
    .custom((value, helper) => {
      const { catalogCodes } = helper.state.ancestors[0];
      if ((catalogCodes === null && value === null) || (catalogCodes !== null && value !== null)) {
        return helper.message('Either Catalog Codes or Material Keys are required.');
      }
      return true;
    }),
});

// Fields for all BULK Live Events
const COMMON_LIVE_EVENT_FIELDS_SCHEMA = Joi.object({
  // External ID Field is Mandatory for Bulk Live Event Creation
  externalId: Joi.string().required(),
  name: Joi.string().required(),
  eventType: Joi.string().valid(REMOTE_EVENT, BROADCAST_EVENT).required(),
  liveEventGroupId: Joi.number().integer().required().min(0).options({ convert: false }),
  promoStartDate: Joi.date().required(),
  availabilityStartDate: Joi.date().required(),
  availabilityEndDate: Joi.date().required(),
  imageFileName: Joi.string()
    .required()
    .regex(STRING_WITHOUT_SPACES_REGEX)
    .message('Image file name should not contain spaces'),
  logoFileName: Joi.string()
    .required()
    .regex(STRING_WITHOUT_SPACES_REGEX)
    .message('Logo file name should not contain spaces'),
  scheduleType: Joi.string().valid('scheduled_event').required(),

  // Optional fields
  subtitle: Joi.string().allow('').optional(),
  description: Joi.string().allow('').optional(),
  videoGroupId: Joi.string()
    .valid('ShortformSport', 'ShortformEntertainment', 'ShortformNews', 'manuallyMapped')
    .optional(),
  contentTargetingCategories: Joi.array().items(Joi.string().required()).optional().allow(null),
  genreId: Joi.number().integer().optional().allow(null).min(0).options({ convert: false }),
  genreName: Joi.string().optional().allow(null),
  programStartTime: Joi.string().optional().allow(null),
  programEndTime: Joi.string().optional().allow(null),
  classificationCode: Joi.string()
    .valid(...VALID_CLASSIFICATION_CODES)
    .optional()
    .allow(null, ''),
  seasonId: Joi.number().optional().allow(null).min(0).options({ convert: false }),
  preRoll: Joi.boolean().optional(),
  isFastChannel: Joi.boolean().optional(),
  // Zype channel Id eg: "10ab4dab-910f-4d19-b1bc-f2fdd72d0fa3"
  fastChannel: Joi.when('isFastChannel', {
    is: true,
    then: Joi.string().required(),
    otherwise: Joi.valid(null, '').messages({
      'any.only': 'fastChannel is not allowed when isFastChannel is false',
    }),
  }),
  producerNotes: Joi.string().optional().allow(null),
  upNextEvent: Joi.object({
    name: Joi.string().allow('').required(),
    subtitle: Joi.string().allow('').optional(),
    description: Joi.string().allow('').optional(),
    classificationCode: Joi.string()
      .valid(...VALID_CLASSIFICATION_CODES)
      .optional()
      .allow('', null),
    genreId: Joi.number().integer().optional().allow(null).min(0).options({ convert: false }),
    genreName: Joi.string().allow(null).optional(),
    programStartTime: Joi.date().allow(null).optional(),
    programEndTime: Joi.date().allow(null).optional(),
  }).optional(),
});

// Will use correct Schema depending on eventType
const BULK_LIVE_EVENT_FIELDS_SCHEMA = COMMON_LIVE_EVENT_FIELDS_SCHEMA.when(
  Joi.object({ eventType: REMOTE_EVENT }).unknown(),
  { then: BULK_REMOTE_EVENT_FIELDS_SCHEMA }
)
  .when(Joi.object({ eventType: BROADCAST_EVENT }).unknown(), { then: BULK_BROADCAST_EVENT_FIELDS_SCHEMA })
  .options({ abortEarly: false });

export const CSV_TABLE_HEADER_OFFSET = 4;
export const LIVE_EVENT_COLUMN_NAME = {
  externalId: 'external id',
  name: 'event name',
  subtitle: 'subtitle',
  description: 'description',
  genreName: 'genre name',
  classificationCode: 'classification',
  eventType: 'event type',
  catalogCodes: 'catalog codes',
  materialKeys: 'material keys',
  remoteURL: 'remote asset url',
  ssaiRemoteURL: 'ssai remote url',
  liveEventGroupId: 'event group',
  oztamPublisherId: 'oztam id',
  videoGroupId: 'video group',
  multiUrlEnabled: 'enable multi url',
  ssaiEnabled: 'enable ssai',
  isSports: 'enable live sports',
  promoStartDate: 'promo start date/time',
  availabilityStartDate: 'availability start date/time',
  availabilityEndDate: 'availability end date/time',
  seasonId: 'season',
  imageFileName: 'image',
  logoFileName: 'logo',
  liveEventUrls: {
    akamaiCdnUrls: {
      clearUrl: 'akamai cdn clear url',
      tokenisationUrl: 'akamai cdn tokenisation url',
      encryptionUrl: 'akamai cdn encryption url',
      tokenisationEncryptionUrl: 'akamai cdn tokenisation encryption url',
    },
    cloudfrontCdnUrls: {
      clearUrl: 'cloudfront cdn clear url',
      tokenisationUrl: 'cloudfront cdn tokenisation url',
      encryptionUrl: 'cloudfront cdn encryption url',
      tokenisationEncryptionUrl: 'cloudfront cdn tokenisation encryption url',
    },
    ssaiClearUrl: 'ssai clear url',
    ssaiEncryptionUrl: 'ssai encryption url',
  },
  upNextEvent: {
    genreName: 'up next genre name',
    classificationCode: 'up next classification',
    name: 'up next genre name',
    description: 'up next description',
    subtitle: 'up next subtitle',
    programStartTime: 'up next program start time',
    programEndTime: 'up next program end time',
  },
  isFastChannel: 'fast channel',
  fastChannel: 'fast channel id',
  programStartTime: 'program start time',
  programEndTime: 'program end time',
  preRoll: 'pre roll',
  producerNotes: 'producer notes',
  contentTargetingCategories: 'content targeting categories',
};

const getPropertyName = (field) =>
  Object.keys(LIVE_EVENT_COLUMN_NAME).find((key) => LIVE_EVENT_COLUMN_NAME[key] === field);

const parseArrayCsvField = (field) =>
  field
    ? field
        .replace(/"/g, '')
        .split(',')
        .map((item) => item.trim())
    : null;

const getUpNextEventMetadata = (columnNameToIndicesMap, row) => {
  const result = {};
  const optionalFields = columnNameToIndicesMap.optionalFields;

  const upNextFields = ['name', 'subtitle', 'description', 'classificationCode'];

  upNextFields.forEach((field) => {
    const rowValue = row[optionalFields[LIVE_EVENT_COLUMN_NAME.upNextEvent[field]]];
    result[field] = rowValue || '';
  });

  result.genreName = row[optionalFields[LIVE_EVENT_COLUMN_NAME.upNextEvent.genreName]] || null;
  result.programStartTime =
    getValidDate(row[optionalFields[LIVE_EVENT_COLUMN_NAME.upNextEvent.programStartTime]]) || null;

  result.programEndTime =
    getValidDate(row[optionalFields[LIVE_EVENT_COLUMN_NAME.upNextEvent.programEndTime]]) || null;

  return result;
};

const getOptionalFields = (columnNameToIndicesMap, row) => {
  const result = {};
  const optionalFields = CSV_HEADER.optionalFields;

  optionalFields.forEach((field) => {
    const propertyName = getPropertyName(field);
    const rowValue = row[columnNameToIndicesMap.optionalFields[field]];
    if (field === 'content targeting categories') {
      result[propertyName] = rowValue ? parseArrayCsvField(rowValue) : null;
    } else if (['program start time', 'program end time'].includes(field)) {
      result[propertyName] = getValidDate(rowValue) || null;
    } else if (['pre roll', 'fast channel'].includes(field)) {
      result[propertyName] = rowValue === true;
    } else if (field === 'video group') {
      result[propertyName] = rowValue || 'manuallyMapped';
    } else if (field === 'season') {
      result[propertyName] = parseInt(rowValue, 10) || null;
    } else if (!field.includes('up next')) {
      const defaultValue = ['subtitle', 'description'].includes(field) ? '' : null;
      result[propertyName] = rowValue || defaultValue;
    }
  });

  result.upNextEvent = getUpNextEventMetadata(columnNameToIndicesMap, row);
  return result;
};

const getEventTypeFields = (columnNameToIndicesMap, row) => {
  const eventType = row[columnNameToIndicesMap.mandatoryFields[LIVE_EVENT_COLUMN_NAME.eventType]];
  const eventTypeFields = columnNameToIndicesMap.eventTypeFields;

  const booleanFields = ['ssaiEnabled', 'isSports', 'multiUrlEnabled'];

  const buildBroadcastEventFields = () => ({
    ...booleanFields.reduce((acc, field) => ({ ...acc, [field]: false }), {}),
    materialKeys: parseArrayCsvField(row[eventTypeFields[LIVE_EVENT_COLUMN_NAME.materialKeys]]),
    catalogCodes: parseArrayCsvField(row[eventTypeFields[LIVE_EVENT_COLUMN_NAME.catalogCodes]]),
  });

  const buildRemoteEventFields = () => {
    const result = {};
    const remoteFields = ['oztamPublisherId', 'remoteURL', 'ssaiRemoteURL'];

    remoteFields.forEach((field) => {
      const rowValue = row[eventTypeFields[LIVE_EVENT_COLUMN_NAME[field]]];
      result[field] = rowValue || '';
    });

    booleanFields.forEach((field) => {
      result[field] = row[eventTypeFields[LIVE_EVENT_COLUMN_NAME[field]]] || false;
    });

    if (!!result.multiUrlEnabled) {
      result.liveEventUrls = {
        akamaiCdnUrls: {
          clearUrl: row[eventTypeFields[LIVE_EVENT_COLUMN_NAME.liveEventUrls.akamaiCdnUrls.clearUrl]],
          tokenisationUrl:
            row[eventTypeFields[LIVE_EVENT_COLUMN_NAME.liveEventUrls.akamaiCdnUrls.tokenisationUrl]],
          encryptionUrl:
            row[eventTypeFields[LIVE_EVENT_COLUMN_NAME.liveEventUrls.akamaiCdnUrls.encryptionUrl]],
          tokenisationEncryptionUrl:
            row[
              eventTypeFields[LIVE_EVENT_COLUMN_NAME.liveEventUrls.akamaiCdnUrls.tokenisationEncryptionUrl]
            ],
        },
        cloudfrontCdnUrls: {
          clearUrl: row[eventTypeFields[LIVE_EVENT_COLUMN_NAME.liveEventUrls.cloudfrontCdnUrls.clearUrl]],
          tokenisationUrl:
            row[eventTypeFields[LIVE_EVENT_COLUMN_NAME.liveEventUrls.cloudfrontCdnUrls.tokenisationUrl]],
          encryptionUrl:
            row[eventTypeFields[LIVE_EVENT_COLUMN_NAME.liveEventUrls.cloudfrontCdnUrls.encryptionUrl]],
          tokenisationEncryptionUrl:
            row[
              eventTypeFields[
                LIVE_EVENT_COLUMN_NAME.liveEventUrls.cloudfrontCdnUrls.tokenisationEncryptionUrl
              ]
            ],
        },
        ssaiClearUrl: row[eventTypeFields[LIVE_EVENT_COLUMN_NAME.liveEventUrls.ssaiClearUrl]],
        ssaiEncryptionUrl: row[eventTypeFields[LIVE_EVENT_COLUMN_NAME.liveEventUrls.ssaiEncryptionUrl]],
      };
    }
    return result;
  };

  return eventType === BROADCAST_EVENT ? buildBroadcastEventFields() : buildRemoteEventFields();
};

export function getLiveEventsMetadataFromCsvData(columnNameToIndicesMap, liveEventsMetadataRows) {
  if (!columnNameToIndicesMap || !liveEventsMetadataRows) {
    return [];
  }

  return liveEventsMetadataRows.map((row) => {
    const result = { scheduleType: SCHEDULED_EVENT };

    // Use setField for mandatory fields
    const mandatoryFields = [
      'externalId',
      'name',
      'eventType',
      'liveEventGroupId',
      'imageFileName',
      'logoFileName',
    ];
    mandatoryFields.forEach((field) => {
      const rowValue = row[columnNameToIndicesMap.mandatoryFields[LIVE_EVENT_COLUMN_NAME[field]]];
      result[field] = rowValue || '';
    });

    // Handle dates with getValidDate
    ['promoStartDate', 'availabilityStartDate', 'availabilityEndDate'].forEach((dateField) => {
      result[dateField] = getValidDate(
        row[columnNameToIndicesMap.mandatoryFields[LIVE_EVENT_COLUMN_NAME[dateField]]]
      );
    });

    // Use helper functions for additional fields
    Object.assign(result, getOptionalFields(columnNameToIndicesMap, row));
    Object.assign(result, getEventTypeFields(columnNameToIndicesMap, row));

    return result;
  });
}

export const isCsvFileHeaderValid = (csvHeader) => {
  return CSV_HEADER.mandatoryFields.length === Object.keys(csvHeader).length;
};

export const getColumnNameToIndicesMap = (csvHeader) => {
  if (!csvHeader) {
    return {};
  }
  const CSV_INDICES_TO_COLUMN_NAMES_MAP = {
    mandatoryFields: {},
    optionalFields: {},
    eventTypeFields: {},
  };

  const csvHeaderEntries = Object.entries(csvHeader);

  CSV_HEADER.mandatoryFields.forEach((field) => {
    for (const [key, value] of csvHeaderEntries) {
      if (field.toLowerCase().trim() === (value && value.toLowerCase().trim())) {
        CSV_INDICES_TO_COLUMN_NAMES_MAP.mandatoryFields[value] = key;
      }
    }
  });

  CSV_HEADER.optionalFields.forEach((field) => {
    for (const [key, value] of csvHeaderEntries) {
      if (field.toLowerCase().trim() === value && value.toLowerCase().trim()) {
        CSV_INDICES_TO_COLUMN_NAMES_MAP.optionalFields[value] = key;
      }
    }
  });

  CSV_HEADER.eventTypeFields.forEach((field) => {
    for (const [key, value] of csvHeaderEntries) {
      if (field.toLowerCase().trim() === value && value.toLowerCase().trim()) {
        CSV_INDICES_TO_COLUMN_NAMES_MAP.eventTypeFields[value] = key;
      }
    }
  });

  return CSV_INDICES_TO_COLUMN_NAMES_MAP;
};

export const validateLiveEventMetadata = (liveEventsToUpload) =>
  liveEventsToUpload
    .map((liveEventMetadata, index) => ({
      index,
      externalId: liveEventMetadata.externalId,
      error: BULK_LIVE_EVENT_FIELDS_SCHEMA.validate(liveEventMetadata).error,
    }))
    .filter((item) => item.error !== undefined);
